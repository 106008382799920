import React from 'react';
import classnames from 'classnames';
import ReactSelect from 'react-select';
import styles from './styles/Select.module.scss';

const Select = ({ className, options, defaultValue, placeholder, value, onChange }) => {
  return (
    <ReactSelect
      className={classnames(styles.select, className)}
      classNamePrefix="select"
      options={options}
      defaultValue={defaultValue}
      value={value}
      onChange={onChange}
      isSearchable={false}
      placeholder={placeholder}
    />
  );
};

export default Select;
