import React from 'react';
import classnames from 'classnames';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import Layout from '../components/Layout';
import SEO from '../components/seo';
import LinkButton from '../components/LinkButton';
import useContentfulWiki from '../hooks/useContentfulWiki';
import styles from './styles/why-life-insurance.module.scss';
import ArrowDown from '../images/icons/arrowDown.svg';
import RingSvg from '../images/life/ring.svg';
import BabySvg from '../images/life/baby.svg';
import HouseSvg from '../images/life/house.svg';
import HeartSvg from '../images/life/heart.svg';
import SmallHeartSvg from '../images/life/smallHeart.svg';
import { INSURANCE_TYPES_ICONS } from '../constants/insurance';
import { GET_STARTED_LIFE_ROUTE } from '../constants/routes';
import LifeCalculator from '../components/LifeCalculator';

const imgStyle = { objectFit: 'contain' };

const links = [
  {
    link: '/market-scan',
    title: 'Market scan',
  },
  {
    link: '/why-life-insurance',
    title: 'Life insurance',
    className: styles.bold,
  },
  {
    link: '/about-us',
    title: 'About us',
  },
];

const getBrands = (images) =>
  Array(6)
    .fill()
    .map((_, i) => {
      const key = `brand${i}`;
      return (
        <Img
          key={key}
          className={styles.brand}
          fluid={images[key].childImageSharp.fluid}
          alt={key}
          imgStyle={imgStyle}
        />
      );
    });

const moments = [
  {
    icon: RingSvg,
    text: 'Recently married?',
  },
  {
    icon: BabySvg,
    text: 'Just started a family?',
  },
  {
    icon: HouseSvg,
    text: 'Bought a new home?',
  },
  {
    icon: HeartSvg,
    text: 'Lost a loved one?',
  },
];

const section2Items = [
  {
    icon: SmallHeartSvg,
    title: 'Life is uncertain',
    text:
      'An accident or an illness could throw us the ultimate curve ball today - death.',
  },
  {
    icon: SmallHeartSvg,
    title: 'The ones that make your world',
    text:
      'Who are your loved ones that you will leave behind? Spouse, kids, parents?',
  },
  {
    icon: SmallHeartSvg,
    title: 'Leave your legacy in giving',
    text:
      'What’s your final gift to your loved ones? A roof over their heads, an education, a wedding?',
  },
];

const getCovers = (wiki) => {
  return wiki
    .filter((item) => !!item.coverBlurb)
    .sort((a, b) => a.coverOrder - b.coverOrder);
};

const WhyLifeInsurance = () => {
  const images = useStaticQuery(
    graphql`
      {
        brand0: file(relativePath: { eq: "brands/fidelity.png" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        brand1: file(relativePath: { eq: "brands/southern-cross.png" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        brand2: file(relativePath: { eq: "brands/partners-life.png" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        brand3: file(relativePath: { eq: "brands/cigna.png" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        brand4: file(relativePath: { eq: "brands/aia.png" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 30) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        brand5: file(relativePath: { eq: "brands/asteron.png" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        explore: file(relativePath: { eq: "life/life-explore.png" }) {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `,
  );

  const wiki = useContentfulWiki();
  const covers = getCovers(wiki);

  return (
    <Layout signUpUrl={GET_STARTED_LIFE_ROUTE} navLinks={links}>
      <SEO title="Why Life insurance?" />
      <div className={styles.header}>
        <div className="container">
          <div className={styles.title}>
            <span>Life insurance</span>
          </div>
          <div className={styles.brands}>{getBrands(images)}</div>
        </div>
      </div>
      <div className={classnames(styles.section, 'container')}>
        <div className={styles.sectionTitle}>
          Have you just experienced a <span className={styles.blue}>major</span>{' '}
          life moment?
        </div>
        <div className={styles.moments}>
          {moments.map(({ icon: IconComponent, text }) => (
            <div key={text} className={styles.moment}>
              <IconComponent />
              <b>{text}</b>
            </div>
          ))}
        </div>
        <LinkButton
          href={GET_STARTED_LIFE_ROUTE}
          background="#4187F5"
          className={styles.button}
          eventName="Marketing-Life-Get-Life-Insurance-Click"
        >
          <b>Get life insurance</b>
        </LinkButton>
        <ArrowDown />
      </div>
      <div className={classnames(styles.section2, 'container')}>
        <div className={styles.section2Header}>
          <div className={styles.section2Title}>
            Life insurance takes care of{' '}
            <span className={styles.blue}>your loved ones financially.</span>
          </div>
          <div className={styles.section2Subtitle}>
            Sometimes life just doesn’t go the way you planned. But with Life
            insurance, even in death, you can have certainty that your loved
            ones will be financially protected.
          </div>
        </div>
        <div className={styles.section2Items}>
          {section2Items.map(({ icon: IconComponent, title, text }) => (
            <div key={title} className={styles.section2Item}>
              <IconComponent />
              <b>{title}</b>
              <div>{text}</div>
            </div>
          ))}
        </div>
      </div>
      <div className={classnames(styles.calculator, 'container')}>
        <LifeCalculator />
      </div>
      <div className={styles.coverSection}>
        <div className="container">
          <div className={styles.sectionTitle}>
            There’s more to life insurance!
            <br />
            Ask an expert about these additional covers.
          </div>
          <div className={styles.covers}>
            {covers.map((c) => {
              const IconComponent = INSURANCE_TYPES_ICONS[c.alias || c.title];
              return (
                <div key={c.title} className={styles.cover}>
                  <IconComponent className={styles.coverIcon} />
                  <b className={styles.coverTitle}>{`${c.title} insurance`}</b>
                  <div className={styles.coverDesc}>{c.coverBlurb}</div>
                </div>
              );
            })}
          </div>
          <LinkButton
            href={GET_STARTED_LIFE_ROUTE}
            background="#34363E"
            className={styles.coversButton}
            eventName="Marketing-Life-Get-Advice-Click"
          >
            <b>Get Advice</b>
          </LinkButton>
        </div>
      </div>
      <div className={classnames(styles.section, 'container')}>
        <div className={styles.explore}>
          <div className={styles.left}>
            <Img
              className={styles.exploreImg}
              fluid={images.explore.childImageSharp.fluid}
              alt="Explore your options"
              imgStyle={imgStyle}
            />
          </div>
          <div className={styles.right}>
            <div className={styles.sectionTitle}>Explore your options.</div>
            <div>
              Connect with an expert insurance adviser to chat about the right
              Life insurance for you. Let them help you find the best cover and
              deal. <b>It’s easy and free. No obligations or spam.</b>
            </div>
            <div className={styles.blue}>
              <b>Review your existing cover or purchase a new cover.</b>
            </div>
            <LinkButton
              href={GET_STARTED_LIFE_ROUTE}
              background="#4187F5"
              className={styles.exploreButton}
              eventName="Marketing-Life-Book-Chat-Click"
            >
              <b>Book a chat</b>
            </LinkButton>
          </div>
        </div>
        <div className={styles.footer}>
          <div className={styles.footerTitle}>
            Protect your family today.
            <br />{' '}
            <span className={styles.blue}>
              Get free advice on life insurance.
            </span>
          </div>
          <LinkButton
            href={GET_STARTED_LIFE_ROUTE}
            background="#34363E"
            className={styles.button}
            eventName="Marketing-Life-Get-Started-Click"
          >
            <b>Get started</b>
          </LinkButton>
        </div>
      </div>
    </Layout>
  );
};

export default WhyLifeInsurance;
