import React from 'react';
import classnames from 'classnames';
import Tooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap.css';
import styles from './styles/HelpTooltip.module.scss';

const HelpTooltip = ({ className, content }) => {
  return (
    <Tooltip
      className={classnames(styles.tooltip, className)}
      trigger={['hover']}
      overlay={(
        <div className={styles.content}>
          {content}
        </div>
      )}
    >
      <div className={classnames(styles.trigger)}>?</div>
    </Tooltip>
  );
};

export default HelpTooltip;
