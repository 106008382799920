import React, { useRef, useState } from 'react';
import classnames from 'classnames';
import { fetch } from 'whatwg-fetch';
import styles from './styles/LifeCalculator.module.scss';
import Button from './Button';
import HelpTooltip from './HelpTooltip';
import Spinner from './Spinner';
import LinkButton from './LinkButton';
import Select from './Select';
import useSiteMetadata from '../hooks/useSiteMetadata';
import { debounce } from '../common/utils';
import { GET_STARTED_LIFE_ROUTE } from '../constants/routes';
import { segmentTrackEvent } from '../common/utils';

const initialState = {
  age: '',
  amount: null,
  loading: false,
  estimate: '',
};

const options = [
  {
    value: 100000,
    label: '$100,000',
  },
  {
    value: 250000,
    label: '$250,000',
  },
  {
    value: 500000,
    label: '$500,000',
  },
];

const isValidAge = (age) => {
  const ageInt = parseInt(age);
  return ageInt > 15 && ageInt < 71;
};

const isValid = (age, amount) => {
  if (age && amount) {
    return isValidAge(age);
  }
  return false;
};

const mergeState = (obj) => (prevState) => ({
  ...prevState,
  ...obj,
});

const getEstimate = async (baseUrl, data) => {
  const url = `${baseUrl}/external/quote`;
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
  return response.json();
};

const AMOUNT_TOOLTIP =
  'Your sum insured is the amount you would like to leave behind for your loved ones. For example, to pay off the mortgage, or your kids education or wedding etc...';
const ESTIMATE_TOOLTIP =
  'This is just an estimate. We do not take into account your gender, smoking history and occupation which factors into the price. Click Get Started to talk to an expert for your options and prices.';

const LifeCalculator = ({ className }) => {
  const debounceRef = useRef();
  const siteMetadata = useSiteMetadata();
  const [state, setState] = useState(initialState);

  const updateEstimate = async (age, amount) => {
    setState(mergeState({ loading: true }));
    segmentTrackEvent('Marketing-Life-Calculator-Get-Estimate');
    const data = await getEstimate(siteMetadata.APP_URL, {
      age,
      insuredAmount: amount,
    });
    setState(
      mergeState({
        estimate: data.Monthly,
        loading: false,
      }),
    );
  };

  const handleClick = () => {
    segmentTrackEvent('Marketing-Life-Calculator-See-Prices-Click');
    if (state.age && state.amount) {
      updateEstimate(state.age, state.amount.value);
    }
  };

  const handleInputChange = (e) => {
    e.persist();
    const value = e.target.value;
    setState(mergeState({ age: value }));

    if (state.estimate && isValidAge(value)) {
      debounceRef.current = debounce(
        () => {
          updateEstimate(value, state.amount.value);
        },
        500,
        debounceRef.current,
      );
    }
  };

  const handleSelectChange = (data) => {
    // Value is the option item: { value: string, label: number }
    setState(mergeState({ amount: data }));

    if (state.estimate) {
      updateEstimate(state.age, data.value);
    }
  };

  return (
    <div className={classnames(styles.container, className)}>
      <div className={styles.title}>
        Don’t know where to start? Let us help you.
      </div>
      <div className={styles.subtitle}>
        See how much your life insurance could cost
      </div>
      <div className={styles.fields}>
        <input
          name="age"
          className={styles.age}
          value={state.age}
          type="number"
          onChange={handleInputChange}
          placeholder="Your age"
          min={16}
          max={70}
        />
        <div className={styles.selectContainer}>
          <Select
            className={styles.select}
            options={options}
            placeholder="Pick a sum insured"
            value={state.amount}
            onChange={handleSelectChange}
          />
          <HelpTooltip content={AMOUNT_TOOLTIP} className={styles.tooltip} />
        </div>
      </div>
      {state.estimate || state.loading ? (
        <>
          <div className={styles.estimate}>
            {state.loading ? (
              <Spinner inverted={true} className={styles.spinner} />
            ) : (
              `$${state.estimate.low} - $${state.estimate.high} /month`
            )}
            <HelpTooltip
              content={ESTIMATE_TOOLTIP}
              className={styles.tooltip}
            />
          </div>
          <div className={styles.btnContainer}>
            <div className={styles.btnText}>Let an expert help you</div>
            <LinkButton
              href={GET_STARTED_LIFE_ROUTE}
              background="#4187F5"
              className={styles.getStarted}
              eventName="Marketing-Life-Calculator-Get-Started-Click"
            >
              <b>Get started</b>
            </LinkButton>
          </div>
        </>
      ) : (
        <Button
          className={styles.button}
          text="See prices"
          onClick={handleClick}
          disabled={!isValid(state.age, state.amount && state.amount.value)}
        />
      )}
    </div>
  );
};

export default LifeCalculator;
